import { useStaticQuery, graphql } from "gatsby";

import CardBlogsData from "../data/blog-cards-data";
import EsCardBlogsData from "../data/es-blog-cards-data";

export default function useCardBlogsData(cardSlugs = [], { isSpanish = false } = {}) {
  const imagesData = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "blogImages" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      esAllFile: allFile(filter: { sourceInstanceName: { eq: "esBlogImages" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  // Utility functions for searching
  const filterByOtherList = (list, arrayToCheck, attr) => {
    if (!arrayToCheck || arrayToCheck.length === 0) return list;

    return list.filter((item) => {
      if (!item[attr]) return false;

      return arrayToCheck.indexOf(item[attr]) !== -1;
    });
  };

  const getImages = (list, arrayToCheck, attr, attrCheck) => {
    return list.filter((item) => {
      if (!item[attr]) return false;

      return arrayToCheck.find((checking) => {
        if (!checking[attrCheck]) return false;

        return checking[attrCheck] === item[attr];
      });
    });
  };

  // Get imagesNodes
  const imageNodes = [...imagesData.allFile.nodes];
  const esImageNodes = [...imagesData.esAllFile.nodes];

  const cardDataSet = isSpanish ? EsCardBlogsData : CardBlogsData;
  const imageDataSet = isSpanish ? esImageNodes : imageNodes;

  // Using the cardSlugs, find the card data from the /data/blog-cards-data file
  const cardsData = filterByOtherList(cardDataSet, cardSlugs, "slug");
  //  console.log("CARD BLOG DATA 1: ", cardsData);

  // From the image nodes, get the ones needed by a card by matching the node relativePath to the card imageFilterPath attr
  const cardsImages = getImages(imageDataSet, cardsData, "relativePath", "imageFilterPath");
  // console.log("FILTERED CARD Images ", cardsImages);

  // To the card data, add the found image
  const cardsWithImages = cardsData.map((card) => {
    const cardImage = cardsImages.find((cardImage) => cardImage.relativePath === card.imageFilterPath);
    const imgVariable = cardImage ? cardImage.childImageSharp.gatsbyImageData : null;
    return {
      ...card,
      imgVariable
    };
  });

  // Make sure the card data is in the same order as the slugs are passed.
  // We achieve this by mapping the slugs to the object, then filtering for any falsy match using Boolean
  if (!cardSlugs || cardSlugs.length === 0) return cardsWithImages;
  const orderedCardsData = cardSlugs.map((slug) => cardsWithImages.find((card) => card.slug === slug)).filter(Boolean);
  return orderedCardsData;
}
