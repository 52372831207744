const EsCardBlogsData = [
  {
    date: "2024-02-10",
    title: "4 sencillos pasos para que puedas ahorrar $100 dólares al mes",
    slug: "/es/tu-decides/ahorrar-100-dolares-al-mes",
    description:
      "El Año Nuevo es un buen momento para echarle un ojo a tus finanzas y ver cómo puedes comenzar a ahorrar. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    text: "Para la mayoría de nosotros, el costo de los regalos de navidad, las fiestas y la decoración va sumando… y puede ser fácil gastar más de lo que deberíamos cuando llega la temporada de fiestas.",
    imageFilterPath: "ahorrar-100-dolares-al-mes/card-save-100-600-es.jpg",
    imageAlt: "Billetes de $100 en una alcancía."
  },
  {
    date: "2024-04-19",
    title: "Ahorrar para la Universidad: Lo que debes de saber",
    slug: "/es/tu-decides/ahorrar-para-la-universidad",
    description:
      "Ahorrar para la Universidad: Lo que debes de saber. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "ahorrar-para-la-universidad/thumbnail-paying-for-college-010824.jpg",
    imageAlt: "Young graduate woman embracing her mother on graduation."
  },
  {
    date: "2024-02-11",
    title: "Cómo administrar tu dinero en tiempos buenos y malos",
    slug: "/es/tu-decides/como-administrar-tu-dinero",
    description:
      "Las decisiones que tome en esos momentos con respecto a ahorrar y a gastar pueden tener un impacto importante en tu futuro financiero. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    text: "A pesar de que hay algunas excepciones, la mayoría de la gente gasta más o menos la misma cantidad de dinero cada mes.",
    imageFilterPath: "como-administrar-tu-dinero/thumbnaill-cómo-administrar-032124.jpg",
    imageAlt: "Pareja presupuestando con laptop y papeles."
  },
  {
    date: "2024-02-08",
    title: "Cómo aumentar su puntaje crediticio",
    slug: "/es/tu-decides/como-aumentar-su-puntaje-crediticio",
    description:
      "Puede ser un buen momento para conocer su puntaje crediticio y las formas de mejorarlo. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    text: "Si tiene una nueva casa, automóvil o préstamo en el futuro para 2022, puede ser un buen momento para conocer su puntaje crediticio y las formas de mejorarlo.",
    imageFilterPath: "como-aumentar-su-puntaje-crediticio/card-boost-credit-600.jpg",
    imageAlt: "Pareja sonriendo mientras paga facturas en una laptop."
  },
  {
    date: "2024-02-05",
    title: "Un centavo ahorrado…comience un buen hábito: consejos para aumentar sus ahorros",
    slug: "/es/tu-decides/como-lograr-este-objetivo-de-ahorro",
    description: "¿Cómo logra este objetivo de ahorro? Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "como-lograr-este-objetivo-de-ahorro/thumbnail-increase-savings-091923.jpg",
    imageAlt: "Anciana sonriendo e hija abrazándola."
  },
  {
    date: "2024-01-23",
    title: "Cómo mantener finanzas saludables en 2024",
    slug: "/es/tu-decides/como-mantener-finanzas-saludables",
    description:
      "Aquí te tenemos unas buenas resoluciones financieras y algunos consejos para poder lograrlas. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "como-mantener-finanzas-saludables/thumbnail-how-to-stay-financially-fit-101923.jpg",
    imageAlt: "Pareja sonriendo mientras trabaja en sus finanzas."
  },
  {
    date: "2024-01-25",
    title: "Compra tu Casa: Cómo saber si estás listo para comprar",
    slug: "/es/tu-decides/compra-tu-casa",
    description:
      "Si estás buscando comprar una casa antes de que se incrementen las tasas de interés, es buena idea hacer tu tarea para asegurarte de que estás listo para hacer la compra.",
    imageFilterPath: "compra-tu-casa/thumbnail-what-age-buy-house-091323.jpg",
    imageAlt: "Pareja feliz sentada en el pórtico junto a las plantas."
  },
  {
    date: "2024-01-31",
    title: "Curso Básico para Comprar Casa: Comprendiendo los Costos de Cierre",
    slug: "/es/tu-decides/comprendiendo-los-costos-de-cierre",
    description:
      "WaFd Bank comparte consejos para comprar una casa. Antes de comprar una casa nueva, considere estos factores para determinar si está listo.",
    imageFilterPath: "comprendiendo-los-costos-de-cierre/thumbnail-understanding-closing-costs-092623.jpg",
    imageAlt: "Pareja joven firmando documentos de cierre."
  },
  {
    date: "2024-02-03",
    title: "Consejos para recortar gastos en casa",
    slug: "/es/tu-decides/consejos-para-recortar-gastos-en-casa",
    description:
      "Sigue leyendo para más ideas sobre cómo reducir costos en casa, y sobre cómo hacer un presupuesto. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "consejos-para-recortar-gastos-en-casa/thumbnail-tip-for-cutting-costs-around-house-101923.jpg",
    imageAlt: "Pareja sonriendo mientras hace el papeleo."
  },
  {
    date: "2024-01-24",
    title: "Cómo enseñar a los hijos sobre el dinero",
    slug: "/es/tu-decides/ensenar-a-los-hijos-sobre-el-dinero",
    description:
      "Cuando se trata del futuro financiero de tus hijos, los expertos dicen que nunca es muy pronto para hablarles de dinero. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "ensenar-a-los-hijos-sobre-el-dinero/thumbnail-teach-financial-literacy-071723.jpg",
    imageAlt: "Madre e hija sonrientes poniendo monedas en una alcancía."
  },
  {
    date: "2024-02-02",
    title: "Pepararse Para Las Incertidumbres Economicas",
    slug: "/es/tu-decides/prepararse-para-las-incertidumbres-economicas",
    description: "Aprenda consejos sobre cómo lidiar con las incertidumbres económicas en los próximos meses.",
    imageFilterPath: "prepararse-para-las-incertidumbres-economicas/thumbnail-prepare-economic-uncertainties-122823.jpg",
    imageAlt: "Pareja madura de mediana edad presupuestando con laptop y papeles."
  },
  {
    date: "2024-01-28",
    title: "Presupuesto 101: Consejos para crear un presupuesto que funcione para usted",
    slug: "/es/tu-decides/presupuesto-101",
    description:
      "Así que... ¿a dónde va tu dinero y qué debes de hacer con lo que te queda de excedente?  Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "presupuesto-101/thumbnail-getting-finances-on-track-03082023-730.jpg",
    imageAlt: "Mujer feliz con gafas usando calculadora y laptop para presupuestar."
  },
  {
    date: "2024-01-29",
    title: "Lista De Verificación De Casa Nueva",
    slug: "/es/tu-decides/lista-de-verificacion-de-casa-nueva",
    description:
      "¿Casa nueva? ¿Está planeando mudarse? Asegúrese de mantener estos elementos en la parte superior de su lista de tareas pendientes.",
    imageFilterPath: "lista-de-verificacion-de-casa-nueva/new-home-600.jpg",
    imageAlt: "Mujer abrazando a hombre y sosteniendo las llaves de la casa nueva."
  },
  {
    date: "2024-01-26",
    title: "Qué hacer con el retorno de impuestos de este año",
    slug: "/es/tu-decides/que-hacer-con-el-retorno-de-impuestos-anual",
    description:
      "Para mejorar tu salud financiera, te presentamos aquí algunas maneras en las que puedes utilizar (o ahorrar) el dinero que recibas de tu retorno de impuestos. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "que-hacer-con-el-retorno-de-impuestos-anual/thumbnail-6-ideas-to-use-your-tax-refund-091823.jpg",
    imageAlt: "Mujer celebrando, mirando la declaración de devolución de impuestos."
  },
  {
    date: "2024-02-04",
    title: "Cierra tu año con buena salud financiera",
    slug: "/es/tu-decides/termina-anualmente-con-buena-salud-financiera",
    description:
      "Aquí te presentamos algunos consejos para que puedas cerrar el año con el pie derecho cuando se trata de tus finanzas. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "termina-anualmente-con-buena-salud-financiera/thumbnail-year-financial-shape-091823.jpg",
    imageAlt: "Padres compran con una laptop en casa mientras su hija dibuja."
  },
  {
    date: "2024-02-01",
    title: "Cómo saber cuándo vender su casa",
    slug: "/es/tu-decides/vender-su-casa",
    description: "¿Estás pensando en vender tu casa? Y si es así, ¿cómo sabes si es el momento adecuado?",
    imageFilterPath: "vender-su-casa/thumbnail-when-to-sell-your-home-122623.jpg",
    imageAlt: "Una pareja revisando papeles y trabajando en una laptop."
  }
];

export default EsCardBlogsData;
